import React, { Component } from "react"
import { GlobalContainer, GlobalSection } from "./GlobalStyle"
import Image from "./Image"
import styled from "styled-components"
import { withTranslation } from "react-i18next"

const StyledPerks = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const StyledPerkItem = styled.div`
  background-color: #fff;
  padding: 70px 15px;
  width: calc(100% / 4 - 30px);
  margin: 30px 15px 0;
  text-align: center;
  @media (max-width: 1600px) {
    padding: 30px 15px;
  }
  @media (max-width: 1200px) {
    width: calc(100% / 3 - 30px);
  }
  @media (max-width: 900px) {
    width: calc(100% / 2 - 30px);
  }
  @media (max-width: 600px) {
    width: 100%;
    margin: 30px 0 0;
  }
  &:first-of-type {
    background-color: transparent;
    text-align: left;
    padding: 0;
    h2 {
      font-size: 60px;
      font-weight: 400;
      line-height: 1em;
      @media (max-width: 1600px) {
        font-size: 40px;
      }
      &:after {
        content: "";
        display: block;
        position: relative;
        width: 100px;
        height: 2px;
        background-color: var(--main);
        margin: 20px 0;
      }
    }
    p {
      opacity: 0.7;
      @media (max-width: 1600px) {
        font-size: 15px;
        line-height: 1.5em;
      }
    }
  }
`
const StyledPerkItemIcon = styled.div`
  height: 80px !important;
  width: 80px !important;
  margin: 0 auto 20px;
  img {
    height: 80px !important;
  }
  @media (max-width: 1600px) {
    height: 48px !important;
    width: 48px !important;
    img {
      height: 48px !important;
    }
  }
`
const StyledPerkItemTitle = styled.div`
  font-weight: 800;
  font-size: 30px;
  word-wrap: break-spaces;
  margin-top: 8px;
  line-height: 1.4em;
  @media (max-width: 1600px) {
    font-size: 24px;
  }
`
const StyledPerkItemDescription = styled.div`
  padding: 12px 0;
  font-size: 16px;
  font-weight: 400;
  opacity: .7;
    @media (max-width: 1600px) {
      font-size: 15px;
      line-height: 1.5em;
    }
  }
`

const perks = [
  {
    id: 1,
    delay: "300",
    icon: "router",
    name: "homepage.perks1Name",
    description: "homepage.perks1Desc",
  },
  {
    id: 2,
    delay: "200",
    icon: "locker",
		name: "homepage.perks2Name",
    description: "homepage.perks2Desc",
  },
  {
    id: 3,
    delay: "300",
    icon: "kitchen",
		name: "homepage.perks3Name",
    description: "homepage.perks3Desc",
  },
  {
    id: 4,
    delay: "400",
    icon: "bicycle",
		name: "homepage.perks4Name",
		description: "homepage.perks4Desc",
  },
  {
    id: 6,
    delay: "600",
    icon: "printer",
		name: "homepage.perks5Name",
    description: "homepage.perks5Desc",
  },
  {
    id: 7,
    delay: "700",
    icon: "projector",
		name: "homepage.perks6Name",
    description: "homepage.perks6Desc",
  },
  {
    id: 8,
    delay: "800",
    icon: "security-camera",
		name: "homepage.perks7Name",
		description: "homepage.perks7Desc",
  },
  {
    id: 9,
    delay: "800",
    icon: "shower",
    name: "homepage.perks8Name",
		description: "homepage.perks8Desc",
  },
  {
    id: 10,
    delay: "800",
    icon: "bucket",
		name: "homepage.perks9Name",
		description: "homepage.perks9Desc",
  },
]

class LandingPerks extends Component {
  render() {
    return (
      <GlobalSection bg={"var(--gray)"}>
        <GlobalContainer>
          <StyledPerks>
            <StyledPerkItem
              data-sal="slide-right"
              data-sal-delay="100"
              data-sal-duration="400"
            >
              <h2>
                <strong>{this.props.t("homepage.perksTitle1")}</strong>{" "}
                {this.props.t("homepage.perksTitle2")}
              </h2>
              <p>{this.props.t("homepage.perksText")}</p>
            </StyledPerkItem>
            {perks.map(item => (
              <StyledPerkItem
                key={item.id}
                data-sal="slide-up"
                data-sal-delay={item.delay}
                data-sal-duration="400"
              >
                <StyledPerkItemIcon>
                  <Image filename={"perks/" + item.icon} alt={item.name} />
                </StyledPerkItemIcon>
                <StyledPerkItemTitle>  {this.props.t(item.name)}</StyledPerkItemTitle>
                <StyledPerkItemDescription>
                  {this.props.t(item.description)}
                </StyledPerkItemDescription>
              </StyledPerkItem>
            ))}
          </StyledPerks>
        </GlobalContainer>
      </GlobalSection>
    )
  }
}
export default withTranslation()(LandingPerks)
