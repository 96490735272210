import React, { Component } from "react"
import { GlobalContainer, GlobalSection, GlobalSectionHeader } from "./GlobalStyle"
import Slider from 'react-slick'
import Image from "./Image"
import styled from "styled-components"
import { withTranslation } from 'react-i18next';

const StyledSpacesSlide = styled.div`
  display: flex;
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`
const StyledSliderWrap = styled.div`
  position: relative;
  button {
    background-color: #fff;
    position: absolute;
    padding: 30px;
    z-index: 2;
   border-radius: 50%;
   box-shadow: inset 0 0 0 3px #fff, 0 8px 33px 0 rgba(156,144,210,.4);
   &:hover, &:focus {
    background: #fff;
   }
    &:before {
      content: "";
      display: block;
      height: 10px;
      width: 10px;
      border-left: 2px solid #333;
      border-bottom: 2px solid #333;
      position: absolute;
      top: calc(50% - 5px);
      left: calc(50% - 5px);
    }
    &.slick-next {
      &:before {
        transform: rotate(-135deg) translateX(2px);
      }
      @media (max-width: 1000px) {
        right: 100px;
        top: auto;
        bottom: calc(500px + 130px);
      }
      @media (max-width: 600px) {
        right: 50px;
        bottom: calc(500px - 10px);
      }
      @media (max-width: 400px) {
        right: 20px;
      }
    }
    &.slick-prev {
      &:before {
        transform: rotate(45deg);
      }
      @media (max-width: 1000px) {
        left: auto;
        right: 180px;
        top: auto;
        bottom: calc(500px + 130px);
      }
      @media (max-width: 600px) {
        right: 130px;
        bottom: calc(500px - 10px);
      }
      @media (max-width: 400px) {
        right: 90px;
      }
    }
  }
`
const StyledSpacesSlideText = styled.div`
  width: 500px;
  background-color: #fff;
  padding: 70px;
  height: 650px;
  @media (max-width: 1600px) {
    width: 440px;
  }
  @media (max-width: 1000px) {
    width: 95%;
    margin: 0 auto;
    position: relative;
    top: -200px;
    z-index: 10;
    height: 500px;
    padding: 40px;
  }
  @media (max-width: 600px) {
    top: -50px;
  }
  h3 {
    font-size: 48px;
    line-height: 1.3em;
    margin-bottom: 30px;
    font-weight: 800;
    @media(max-width:550px) {
      font-size: 32px;
    }
    &:after {
      content: '';
      display: block;
      height: 2px;
      margin-top: 10px;
      width: 100px;
      background-color: var(--main);
    }
  }
  p {
    margin-bottom: 40px;
    @media(max-width: 550px) {
      font-size: 15px;
    }
  }
`
const StyledSpacesSlideImage = styled.div`
  flex: 2;
  .gatsby-image-wrapper {
    height: 100%;
    max-height: 650px;
  }
  img {
    height: 100%;
  }
`
const StyledSpacesSlideDetails = styled.div`
  display: flex;
  margin: 20px 0;
  height: 30px;
  strong {
    margin-left: 4px;
  }
  img {
    width: 30px;
    margin-right: 15px;
  }
`

const slides = [
  {
    id: 1,
    name: "homepage.spaces1Name",
    image: "patio.jpg",
    description: "homepage.spaces1Desc",
    details: [
      {
        name: "homepage.spacesArea",
        value: "30m2",
        icon: "area.svg"
      },
      {
        name: "homepage.spacesDesks",
        value: "6",
        icon: "chair.svg"
      },
      {
         name: "homepage.spacesSun",
        value: "∞",
        icon: "sun.svg"
      }
    ]
  },
  {
    id: 2,
    name: "homepage.spaces2Name",
    image: "kuchnia.jpg",
		description: "homepage.spaces2Desc",
    details: [
      {
         name: "homepage.spacesArea",
        value: "18m2",
        icon: "area.svg"
      },
      {
          name: "homepage.spacesDesks",
        value: "8",
        icon: "chair.svg"
      },
      {
        name: "homepage.spacesCups",
        value: "∞",
        icon: "coffee.svg"
      }
    ]
  },
  {
    id: 3,
    name: "homepage.spaces3Name",
    image: "parking.jpeg",
		description: "homepage.spaces3Desc",
    details: [
      {
         name: "homepage.spacesDistance",
        value: "50m",
        icon: "area.svg"
      },
      {
         name: "homepage.spacesPlaces",
        value: "80",
        icon: "chair.svg"
      }
    ]
  },
  {
    id: 4,
    name: "homepage.spaces4Name",
    image: "cowork.jpg",
		description: "homepage.spaces4Desc",
    details: [
      {
         name: "homepage.spacesArea",
        value: "225m2",
        icon: "area.svg"
      },
      {
        name: "homepage.spacesDesks",
        value: "14",
        icon: "chair.svg"
      },
      {
         name: "homepage.spacesPrice",
        value: "od 2zł/h",
        icon: "debit-card.svg"
      }
    ]
  }
]

class LandingSpaces extends Component {
  render() {
    return (
      <GlobalSection bg={"#eee"}>
        <GlobalContainer>
          <GlobalSectionHeader>
            <h2 data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">{this.props.t('homepage.spacesTitle')}</h2>
            <p data-sal="slide-up" data-sal-delay="200" data-sal-duration="400">{this.props.t('homepage.spacesHeader')}</p>
          </GlobalSectionHeader>
          <StyledSliderWrap data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">
            <Slider>
              {
                slides.map((item) =>
                  <div key={item.id}>
                    <StyledSpacesSlide>
                      <StyledSpacesSlideText>
                        <h3>  {this.props.t(item.name)}</h3>
                        <p>
												{this.props.t(item.description)}
                        </p>
                        <div>
                          {
                            item.details.map((list) => (
                                <StyledSpacesSlideDetails key={list.name}>
                                  <Image filename={"icons/" + list.icon} />
																	{this.props.t(list.name)}:
                                  <strong>{list.value}</strong>
                                </StyledSpacesSlideDetails>
                              )
                            )}
                        </div>
                      </StyledSpacesSlideText>
                      <StyledSpacesSlideImage>
                        <Image filename={"spaces/" + item.image} alt="patio" />
                      </StyledSpacesSlideImage>
                    </StyledSpacesSlide>
                  </div>
                )
              }
            </Slider>
          </StyledSliderWrap>
        </GlobalContainer>
      </GlobalSection>
    )
  }
}
export default withTranslation()(LandingSpaces)
