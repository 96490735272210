import React, { Component } from "react"
import { GlobalContainer, GlobalSection, GlobalSectionHeader, ButtonPlain } from "./GlobalStyle"
import Image from "./Image"
import styled from "styled-components"
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

const StyledEvents = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledEventItem = styled.div`
  background-color: #f4f4f4;
  padding: 30px;
  transition: all .3s ease;
  margin-top: 30px;
  display: flex;
  align-items: center;
  @media (max-width: 1000px) {
    flex-direction: column;
    text-align: center;
  }
`
const StyledEventItemLogo = styled.div`
  width: 150px;
  height: 150px;
  background: #fff;
  position: relative;
  margin-right: 20px;
  @media (max-width: 1000px) {
    margin-right: 0;
    width: 100px;
    height: 100px;
  }
  .gatsby-image-wrapper {
    position: absolute;
    min-width: 150px;
    width: 100%;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
  img {
    position: absolute;
    min-width: 150px;
    width: 100%;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    @media (max-width: 1000px) {
      min-width: 100px;
    }
`
const StyledEventItemTitle = styled.div`
  font-weight: bold;
  font-size: 1.6em;
`
const StyledEventItemType = styled.div`
  font-weight: 600;
  display: inline-block;
  position: relative;
  font-size: 0.9em;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--main);
    bottom: -2px;
    left: 0;
  }
`
const StyledEventItemDescription = styled.div`
  font-size: .9em;
`
const StyledEventItemText = styled.div`
  padding-right: 20px;
  flex: 1;
  @media (max-width: 1000px) {
    padding: 0;
    margin: 30px 0;
  }
`
const StyledEventItemDate = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 120px;
  text-align: center;
  @media (max-width: 1200px) {
    margin: 0 80px;
  }
  div:nth-child(1) {
    font-size: 3.2em;
    font-weight: 800;
    line-height: 1em;
  }
  div:nth-child(2) {
    font-weight: 800; 
  }
`

const events = [
  {
    id: 1,
    status: "inactive",
    delay: "300",
    type: "Meetup",
    logo: "pygda.jpeg",
    day: "27",
    month: "stycznia",
    time: "18:00",
    name: "Pygda #53",
    link: "https://www.meetup.com/PyGda-pl/events/267774235/",
    description: "W ostatni poniedziałek stycznia (27.01) zapraszamy na #53 spotkanie PyGDA. Spędź razem z nami popołudnie aby dowiedzieć się czegoś ciekawego ze świata Pythona.",
  },
  {
    id: 2,
    status: "inactive",
    delay: "300",
    type: "Meetup",
    logo: "kubernights.png",
    day: "30",
    time: "18:00",
    month: "stycznia",
    name: "Kubernights #1",
    link: "https://www.meetup.com/Kubernights/events/267266189/",
    description: "Pierwsze wydarzenie w Trójmieście, w pełni poświęcone Kubernetesowi!",
  },
  {
    id: 3,
    status: "inactive",
    delay: "400",
    type: "Meetup",
    logo: "trojkaster.jpg",
    day: "13",
    time: "17:00",
    month: "luty",
    name: "Trójkaster s01e01 - Zakochaj się w podcastach",
    link: "https://www.facebook.com/events/499342994104066/permalink/502167333821632/",
    description: "Inauguracyjne spotkanie trójmiejskich podcasterów - wyjątkowo otwarte także dla słuchaczy. Przyjdź i zakochaj się w podcastach!",
  },
  {
    id: 6,
    status: "active",
    delay: "400",
    type:'events.type6',
    logo: "fundacja-dla-ludzi.png",
    day: "19-21",
    time: "",
    month: 'events.month6',
    name: 'events.title6',
    link: "https://www.dlaludzi.org/events/antycele-nieswiadomosci-4/?occurrence=2023-05-19",
    description: 'events.text6',
  },
  {
    id: 4,
    status: "inactive",
    delay: "400",
    type: "Meetup",
    logo: "trojkaster.jpg",
    day: "25",
    time: "17:00",
    month: "marca",
    name: "Trójkaster s01e02 - Daj się usłyszeć",
    link: "https://www.facebook.com/events/235879187414726/",
    description: "Kolejne spotkanie trójmiejskich podcasterów znowu odbędzie się na Leśnej! Zapraszamy aktywnych podcasterów i osoby zainteresowane tworzeniem swojego podcastu.",
  },
  {
    id: 5,
    status: "inactive",
    delay: "400",
    type: "Meetup",
    logo: "kubernights.png",
    day: "26",
    time: "18:00",
    month: "marca",
    name: "Kubernights #2",
    link: "https://www.facebook.com/events/2543805852571644/",
    description: "Pierwsze wydarzenie w Trójmieście, w pełni poświęcone Kubernetesowi!",
  }
]

class EventList extends Component {

  render() {
    return (
      <GlobalSection>
        <GlobalContainer>
          {this.props.landing &&
          <GlobalSectionHeader>
            <h2 data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">{this.props.t('homepage.events')}</h2>
            <p data-sal="slide-up" data-sal-delay="200" data-sal-duration="400">{this.props.t('homepage.eventsTitle')}</p>
          </GlobalSectionHeader>
          }
          <StyledEvents>
            {events.map((item) =>
             item.status === "active" &&
              <StyledEventItem key={item.id} data-sal="slide-up" data-sal-delay={item.delay} data-sal-duration="400">
                <StyledEventItemLogo>
                  <Image filename={"events/" + item.logo } alt="event logo"/>
                </StyledEventItemLogo>
                <StyledEventItemText>
                  <StyledEventItemType>{ this.props.t(item.type) }</StyledEventItemType>
                  <StyledEventItemTitle>{ this.props.t(item.name) }</StyledEventItemTitle>
                  <StyledEventItemDescription>{ this.props.t(item.description) }</StyledEventItemDescription>
                  <ButtonPlain href={item.link} target="_blank" rel="noopener noreferrer">{this.props.t('events.btn')}</ButtonPlain>
                </StyledEventItemText>
                <StyledEventItemDate>
                  <div>{item.day}</div>
                  <div>{this.props.t(item.month)}</div>
                  <div>{item.time}</div>
                </StyledEventItemDate>
              </StyledEventItem>
            )}
          </StyledEvents>
        </GlobalContainer>
      </GlobalSection>
    )
  }
}
export default withTranslation()(EventList)
