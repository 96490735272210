import React, { Component } from "react"
import { Link } from "gatsby"
import {
  GlobalContainer,
  GlobalSectionHeader,
  GlobalSection,
  Button,
} from "./GlobalStyle"
import styled from "styled-components"
import { withTranslation } from "react-i18next"

const StyledOfferFlex = styled.div`
  display: flex;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`
const StyledOfferTile = styled.div`
  flex: 1;
  padding: 100px 40px;
  text-align: center;
  background-color: var(--gray);
  position: relative;
  @media (max-width: 1600px) {
    padding: 70px 40px 50px;
  }
  @media (max-width: 1000px) {
    margin-top: 20px;
  }
  &:nth-child(2) {
    border-top: 3px solid var(--main);
    background-color: #fff;
  }
`
const StyledOfferTileTitle = styled.div`
  font-size: 1.2em;
  font-weight: 600;
  &:after {
    content: "";
    display: block;
    height: 2px;
    width: 60px;
    margin: 20px auto;
    background-color: var(--main);
  }
`
const StyledOfferTileDescription = styled.div`
  font-size: 0.9em;
  opacity: 0.6;
  margin-bottom: 20px;
`
const StyledOfferButtonWrap = styled.div`
  a {
    text-transform: uppercase;
    color: var(--main);
    font-weight: 600;
    font-size: 0.9em;
  }
`
const StyledOfferPromo = styled.div`
  display: flex;
  margin: 100px auto 50px;
  max-width: 800px;
  align-items: center;
  justify-content: center;
  p {
    margin-right: 10px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    p {
      margin: 30px 0;
      text-align: center;
    }
  }
`
// const StyledOfferPromoIcon = styled.div`
//   display: block;
//   height: 80px;
//   width: 80px;
//   margin-right: 50px;
//   background-image: url(${discount});
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position: center center;
//   @media (max-width: 1000px) {
//     margin-right: 0;
//   }
// `

const offer = [
  {
    id: 1,
    name: "Sale szkoleniowe",
    description:
      "Hub technologiczny to nowoczesny, przyjazny środowisku budynek zlokalizowany w samym centrum Trójmiasta, blisko stacji SKM Sopot Wyścigi. W budynku znajdują się sale szkoleniowe, posiadające zaplecze multimedialne.",
    link: "/oferta/sale-szkoleniowe",
    delay: "6400",
  },
  {
    id: 2,
    name: "Strefa coworkingowa",
    description:
      "Stworzyliśmy strefę coworkingową dla osób ceniących sobie pracę w kreatywnej atmosferze. Oferujemy przyjazną przestrzeń która sprawi, że każdy dzień w biurze będzie dla Ciebie produktywny.",
    link: "/oferta/coworking",
    delay: "200",
  },
  {
    id: 3,
    name: "Wirtualne biuro",
    description:
      "Wirtualne biuro pozwala na korzystanie z adresu naszej lokalizacji bez dodatkowych kosztów wynajmu przestrzeni biurowej oraz zatrudniania pracowników do jego stałej obsługi.",
    link: "/oferta/wirtualne-biuro",
    delay: "400",
  },
]

class LandingOffer extends Component {
  render() {
    return (
      <GlobalSection id="oferta">
        <GlobalContainer>
          <GlobalSectionHeader>
            <h2
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-duration="400"
            >
              {this.props.t("common.offer")}
            </h2>
            <p data-sal="slide-up" data-sal-delay="200" data-sal-duration="400">
              {this.props.t("homepage.offerHeading1")}{" "}
              <strong> {this.props.t("homepage.offerHeading2")}</strong>
            </p>
          </GlobalSectionHeader>
          <StyledOfferFlex>
            <StyledOfferTile
              data-sal="slide-up"
              data-sal-delay={6400}
              data-sal-duration="400"
            >
              <StyledOfferTileTitle>
                {this.props.t("nav.trainingRoom")}
              </StyledOfferTileTitle>
              <StyledOfferTileDescription>
                {this.props.t("homepage.offerDesc1")}
              </StyledOfferTileDescription>
              <StyledOfferButtonWrap>
                <Link to="/oferta/sale-szkoleniowe">
                  {this.props.t("common.more")}
                </Link>
              </StyledOfferButtonWrap>
            </StyledOfferTile>
            <StyledOfferTile
              data-sal="slide-up"
              data-sal-delay={200}
              data-sal-duration="400"
            >
              <StyledOfferTileTitle>
                {this.props.t("nav.coworking")}
              </StyledOfferTileTitle>
              <StyledOfferTileDescription>
                {this.props.t("homepage.offerDesc1")}
              </StyledOfferTileDescription>
              <StyledOfferButtonWrap>
                <Link to="/oferta/coworking">
                  {this.props.t("common.more")}
                </Link>
              </StyledOfferButtonWrap>
            </StyledOfferTile>
            <StyledOfferTile
              data-sal="slide-up"
              data-sal-delay={400}
              data-sal-duration="400"
            >
              <StyledOfferTileTitle>
                {this.props.t("nav.virtualOffice")}
              </StyledOfferTileTitle>
              <StyledOfferTileDescription>
                {this.props.t("homepage.offerDesc1")}
              </StyledOfferTileDescription>
              <StyledOfferButtonWrap>
                <Link to="/oferta/wirtualne-biuro">
                  {this.props.t("common.more")}
                </Link>
              </StyledOfferButtonWrap>
            </StyledOfferTile>
          </StyledOfferFlex>
          <StyledOfferPromo
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-duration="400"
          >
            {/*<StyledOfferPromoIcon />*/}
            {/*<p>*/}
            {/*  Tylko teraz, do 14 lutego <strong>specjalna oferta cenowa</strong> w naszym coworku, sprawdź*/}
            {/*  ofertę już teraz!*/}
            {/*</p>*/}
            <div>
              <Button to="/oferta/coworking">
                {this.props.t("common.offer")}
              </Button>
            </div>
          </StyledOfferPromo>
        </GlobalContainer>
      </GlobalSection>
    )
  }
}
export default withTranslation()(LandingOffer)
