import React from "react"

import SEO from "../components/seo"
import LandingHero from "../components/LandingHero"
import LandingOffer from "../components/LandingOffer"
import LandingPerks from "../components/LandingPerks"
import EventList from "../components/EventList"
import LandingClients from "../components/LandingClients"
import LandingSpaces from "../components/LandingSpaces"

const IndexPage = () => (
  <>
    <SEO
      title="Przestrzeń coworkingowa w centrum Sopotu"
      description="Leśna Hub to przestrzeń biurowa stworzona z myślą o freelancerach i startupach. W zalezności od potrzeb oferujemy coworking, sale konferencyjne oraz wirtualne biura"
    />
    <LandingHero />
    <LandingOffer />
    <LandingSpaces />
    <LandingPerks />
    <EventList landing />
    <LandingClients />
   </>
)

export default IndexPage
