import React, { Component } from "react"
import { GlobalContainer } from "./GlobalStyle"
import Slider from 'react-slick'
import styled, { keyframes } from "styled-components"
import Image from "./Image"
import "../static/assets/slick.min.css"
import "../static/assets/slick-theme.min.css"
import { withTranslation } from 'react-i18next';

const StyledContainer = styled(GlobalContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  color: #fff;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
`
const StyledSlideText = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 5;
  @media (max-width: 1000px) {
    text-align: center;
  }
  @media (max-width: 650px) {
    margin-top: 100px;
  }
  h1 {
    font-size: 4.2em;
    font-weight: 300;
    line-height: 1.2em;
    max-width: 850px;
    @media (max-width: 1600px) {
      font-size: 3.6em;
    }
    @media (max-width: 1400px) {
      font-size: 3.2em;
    }
    @media (max-width: 1000px) {
      font-size: 2.8em;
      max-width: 650px;
      margin: 0 auto;
    }
    @media (max-width: 450px) {
      font-size: 2.2em;
    }
    @media (max-width: 350px) {
      font-size: 2em;
    }
  }
`
// const StyledPromoBox = styled.div`
//   div {
//     display: inline-flex;
//     background-color: rgba(0,0,0,0.3);
//     border-radius: 50px;
//     font-size: 14px;
//     margin-bottom: 20px;
//     padding: 3px 20px 3px 3px;
//     align-items: center;
//     color: rgba(255,255,255,.85);
//     font-weight: 600;
//     @media (max-width: 1200px) {
//       padding: 2px 20px 2px 3px;
//       font-size: 13px;
//     }
//     span {
//       display: block;
//       border-radius: 50px;
//       background-color: #36C29C;
//       padding: 2px 12px;
//       margin-right: 10px;
//       @media (max-width: 1200px) {
//         font-size: .9em;
//         padding: 1px 12px;
//       }
//     }
//     a {
//       color: #fff;
//       display: inline-block;
//       margin-left: 5px;
//       @media (max-width: 450px) {
//         display: none;
//       }
//       &:after {
//         content: '';
//         display: inline-block;
//         height: 4px;
//         width: 4px;
//         border-right: 2px solid #fff;
//         border-bottom: 2px solid #fff;
//         margin-left: 8px;
//         transform: rotate(-45deg);
//         position: relative;
//         top: -1px;
//       }
//     }
//   }
// `

const ButtonOutline = styled.a`
  display: inline-block;
  padding: 6px 30px;
  color: #fff;
  border-radius: 30px;
  border: 2px solid rgba(255,255,255,.3);
  transition: background-color .2s;
  &:hover {
    background-color: rgba(255,255,255,.3);
  }
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`
const StyledSlide = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  .gatsby-image-wrapper {
    min-height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &:after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: 
      radial-gradient(transparent,#1e1b3a),
      linear-gradient(to right,rgba(33, 27, 86, 0.6), transparent);
  }
`
const bgZoom = keyframes`
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`
const fadeInDots = keyframes`
 0% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`
const StyledTiles = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  @media (max-width: 1000px) {
    right: 50%;
    transform: translate(50%);
  }
  @media (max-width: 650px) {
    display: none;
  }
`
const StyledTileBig = styled.div`
  background-color: #9E96FA;
  color: #fff;
  padding: 40px 80px 30px;
  width: 400px;
  text-align: center;
  @media (max-width: 1600px) {
    padding: 40px 75px 30px;
    width: 340px;
  }
  @media (max-width: 1200px) {
    padding: 40px 60px 30px;
    width: 280px;
  }
  @media (max-width: 650px) {
    width: 100%;
    flex: 1;
  }
  div {
    font-size: 85px;
    font-weight: 800;
    border-bottom: 1px solid #fff;
    line-height: 1em;
    @media (max-width: 1600px) {
      font-size: 64px;
    }
    @media (max-width: 1200px) {
      font-size: 48px;
    }
    span {
      font-size: .6em;
      position: relative;
      top: -30px;
    }
  }
  p {
    font-size: 1.5em;
    margin: 20px 0;
    @media (max-width: 1600px) {
      font-size: 1.1em;
    }
    @media (max-width: 1200px) {
      font-size: .9em;
    }
  }
`
const StyledTileSmall = styled.div`
  background-image: linear-gradient(135deg, rgba(42,44,57,1) 0% ,rgba(45,47,62,1) 50%, rgba(82,85,106,1) 100%);
  color: #fff;
  width: 400px;
  padding: 30px 70px;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  position: relative;
  @media (max-width: 1600px) {
    width: 340px;
  }
  @media (max-width: 1200px) {
    padding: 20px 20px;
    width: 250px;
  }
  @media (max-width: 650px) {
    align-items: center;
    justify-content: center;
  }
  div {
    display: flex;
    align-items: center;
    font-size: 1.6em;
    font-weight: 800;
    @media (max-width: 1200px) {
      font-size: 1.2em;
    }
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  a {
    color: var(--main);
    margin-left: 40px;
    position: relative;
    z-index: 100000;
    @media (max-width: 1200px) {
    font-size: .8em;
    }
  }
  & > div{
    a{
      margin-left: 0;
      color: white;
    }
  }
`


const StyledSliderWrap = styled.div`
  overflow: hidden;
  background-color: #1e1b3a;
  .slick-slider {
    height: 100vh;
  }
  .slick-active {
    .gatsby-image-wrapper img {
      animation: 8s ${bgZoom} ease-out;
    }
  }
  .slick-dots {
    bottom: 60px;
    text-align: left;
    max-width: 1540px;
    margin: 0 auto; 
    left: 50%;
    padding-left: 30px;
    transform: translateX(-50%);
    z-index: 0;
    opacity: 0;
    animation: ${fadeInDots} 1.2s 1s ease forwards;
    li button:before {
      content: '';
      height: 6px;
      width: 6px;
      background-color: #fff;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
    @media (max-width: 1600px) {
      max-width: 1170px;
    }
    @media (max-width: 1200px) {
      padding-left: 20px;
    }
    @media (max-width: 1000px) {
      display: none !important;
    }
    .slick-active {
      button:before {
        color: transparent;
        background-color: transparent;
        border: 2px solid #fff;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    button:before {
      color: #fff;
    }
  }
`
const LandingButtonWrap = styled.div`
  margin-top: 50px;
  @media (max-width:1200px) {
    margin-top: 20px; 
  }
`

class LandingHero extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      lazyLoad: 'ondemand',
      fade: true,
      autoplay: true,
      autoplaySpeed: 7000,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <StyledSliderWrap>
        <Slider {...settings}>
          <StyledSlide>
            <Image filename="slider/slide-5.jpg" alt="Zdjęcie coworkingu w tle" />
          </StyledSlide>
          {/*<StyledSlide>*/}
          {/*  <Image filename="slider/slide-1.jpg" alt="Zdjęcie coworkingu w tle" />*/}
          {/*</StyledSlide>*/}
          <StyledSlide>
            <Image filename="slider/slide-3.jpg" alt="Zdjęcie budynku"/>
          </StyledSlide>
        </Slider>
        <StyledContainer>
          <StyledSlideText data-sal="slide-up" data-sal-delay="300" data-sal-duration="400">
            {/*<StyledPromoBox data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">*/}
            {/*  <div>*/}
            {/*    <span>Okazja</span>*/}
            {/*    Do 14 lutego oferta specjalna.*/}
            {/*    <Link to="/oferta/coworking">Sprawdź</Link>*/}
            {/*  </div>*/}
            {/*</StyledPromoBox>*/}
            <h1>{this.props.t('header.homeTitle1')}<strong><br/>{this.props.t('header.homeTitle2')}</strong></h1>
            <LandingButtonWrap data-sal="slide-up" data-sal-delay="500" data-sal-duration="400">
              <ButtonOutline href="#oferta">{this.props.t('header.homeBtn')}</ButtonOutline>
            </LandingButtonWrap>
          </StyledSlideText>
          <StyledTiles>
            <div>
              <StyledTileSmall data-sal="slide-up" data-sal-delay="600" data-sal-duration="400">
                <div data-sal="slide-up" data-sal-delay="800" data-sal-duration="400">
                  <Image filename="icons/phone.svg" alt="ikona telefonu" />
                  <a href="tel:603113673"> 603 113 673 </a>
                </div>
                <a href="mailto:biuro@lesnahub.pl">biuro@lesnahub.pl</a>
              </StyledTileSmall>
            </div>
            <StyledTileBig data-sal="slide-up" data-sal-delay="800" data-sal-duration="400">
              <div data-sal="slide-up" data-sal-delay="1000" data-sal-duration="400">
                850m
                <span>2</span>
              </div>
              <p data-sal="slide-up" data-sal-delay="1000" data-sal-duration="400">{this.props.t('header.homeArea')}</p>
            </StyledTileBig>
          </StyledTiles>
        </StyledContainer>
      </StyledSliderWrap>
    )
  }
}
export default withTranslation()(LandingHero)
