import React, { Component } from "react"
import { GlobalContainer, GlobalSectionHeader, GlobalSection } from "./GlobalStyle"
import styled from "styled-components"
import Image from "./Image"
import { withTranslation } from 'react-i18next';

const StyledClientsLogos = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
  justify-content: center;
  @media(max-width: 1200px) {
    flex-wrap: wrap;
    & > div {
      margin-bottom: 50px !important;
      width: 200px;
    }
  }
  @media(max-width: 600px) {
    flex-direction: column;
    & > div {
      margin-bottom: 50px !important;
      width: 200px;
    }
  }
  & > div {
    width: 200px;
    margin: 0 50px;
    img {
      width: 100%;
    }
  }
 .gormanet {
    width: 120px;
    position: relative;
    top: -10px;
  }
`

class LandingClients extends Component {

  render() {
    return (
      <GlobalSection bg={"var(--gray)"}>
        <GlobalContainer>
          <GlobalSectionHeader>
            <h2 data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">{this.props.t('homepage.clientsHeading')}</h2>
            <p data-sal="slide-up" data-sal-delay="200" data-sal-duration="400">{this.props.t('homepage.clientsText')}</p>
          </GlobalSectionHeader>
          <StyledClientsLogos>
            <div data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">
              <Image filename="clients/devopsbay-logo.png" alt="devopsbay logo" />
            </div>
            <div className="gormanet" data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">
              <Image filename="clients/gormanet-logo.png" alt="gormanet logo" />
            </div>
            <div  data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">
              <Image filename="clients/hirpol-logo.png" alt="hirpol logo" />
            </div>
            <div  data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">
              <Image filename="clients/ilepaneli-logo.png" alt="ilepaneli logo" />
            </div>
          </StyledClientsLogos>
        </GlobalContainer>
      </GlobalSection>
    )
  }
}
export default withTranslation()(LandingClients)
